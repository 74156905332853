@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}
*::selection {
  background: rgb(245, 216, 110);
  color: black;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

section {
  width: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background {
  position: absolute;
  width: 100vw;
  z-index: -1;
  height: 100%;
}

.content {
  width: 82vw;
}
