.arrow-up-link {
  display: block;
  width: 60px;
  height: 100px;

  position: absolute;

  left: 60px;
  bottom: 80px;

  transition: transform 0.2s;
}

.arrow-up-link:hover {
  cursor: pointer;
  transform: scale(1.3);
}

@media screen and (max-width: 950px) {
  .arrow-up-link {
    left: 30px;
    bottom: 30px;
  }
} ;
