.nav-link {
  color: white;
  font-size: 25px;
  padding: 0 8px 6px;
  text-decoration: none;

  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
@media screen and (max-width: 900px) and (max-height: 1200px) {
  .nav-link {
    font-size: 32px;
  }
}
@media screen and (max-width: 800px) {
  .nav-link {
    font-size: 22px;
  }
}
@media screen and (max-width: 550px) {
  .nav-link {
    font-size: 18px;
  }
}

.nav-link:hover {
  cursor: pointer;
  border-bottom-color: white;
  border-right-color: white;
}

.swiss-svg path {
  stroke-width: 50px;
}
